const { sqrt } = Math

export default class Vector2 {
  x: number
  y: number

  constructor(x: number = 0, y: number = 0) {
    this.x = x
    this.y = y
  }

  static fromDifference(v1: Vector2, v2: Vector2) {
    return new Vector2(v2.x - v1.x, v2.y - v1.y)
  }

  getLengthSq() {
    return this.x * this.x + this.y * this.y
  }

  getLength() {
    return sqrt(this.getLengthSq())
  }

  normalize() {
    const length = this.getLength()
    this.x /= length
    this.y /= length
  }

  multiply(value: number) {
    this.x *= value
    this.y *= value
  }

  add(other: Vector2) {
    this.x += other.x
    this.y += other.y
  }
}
