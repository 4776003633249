import classNames from 'classnames'
import React, { ReactNode, useRef, useState } from 'react'

import { ContentfulEmployee } from '../../../@import-types/contentful/Employee.types'
import useAnimationLoop from '../../../hooks/useAnimationLoop'
import useElementBounds from '../../../hooks/useElementBounds'
import Blob from '../../../utils/blob-classes/Blob'
import SpringPoint from '../../../utils/blob-classes/SpringPoint'
import Time from '../../../utils/blob-classes/Time'
import Vector2 from '../../../utils/blob-classes/Vector2'
import { getScrollTop } from '../../../utils/scrollUtils'
import { InternalLink } from '../../_elements/InternalLink/InternalLink'

import styles from './blob-container-zoom-in.module.scss'

interface BlobContainerZoomInProps {
  to: string | null

  blobOptions?: Partial<ConstructorParameters<typeof Blob>[0]>

  className?: string
  employee: ContentfulEmployee

  children: (maskId: string, isMaskActive: boolean) => ReactNode
}

export default function BlobContainerZoomIn({
  to,

  blobOptions,

  className,
  employee,

  children,
}: BlobContainerZoomInProps) {
  const pathRef = useRef<SVGPathElement>(null)
  const [maskId, setMaskId] = useState('')
  const [isHovered, setIsHovered] = useState(false)
  const [isMaskActive, setIsMaskActive] = useState(false)

  const [blob] = useState(
    () =>
      new Blob({
        radius: 250,
        disappearRadius: 100,
        pointCount: 5,
        pointRadiusOffset: 0.4,
        pointSpeedOffset: 0.0025,
        appearSpringConfig: { stiffness: 100, dampness: 8 },
        disappearSpringConfig: { stiffness: 100, dampness: 8 },
        ...blobOptions,
      })
  )
  const [position] = useState(() => new SpringPoint(170, 30))

  const {
    elementRef: rootElementRef,
    elementBoundsRef,
    calculateElementBounds,
  } = useElementBounds<HTMLAnchorElement>(false, false)

  const { start, stop } = useAnimationLoop((time: Time) => {
    const pathEl = pathRef.current

    blob.update(time)
    position.update(time.elapsedS)

    if (!isHovered && blob.isStale) {
      stop()
      setIsMaskActive(false)
    }

    if (pathEl) {
      pathEl.setAttribute('d', blob.toSvgPathD())
      pathEl.setAttribute('transform', `translate(${position.x} ${position.y})`)
    }
  })

  function onMouseEnter(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    calculateElementBounds()

    start()
    // Generating a unique id on the server caused issues with hydration
    // That's why we generate a new id every time
    setMaskId(`${Math.random()}-mask-id`)
    setIsHovered(true)
    setIsMaskActive(true)

    blob.appear()

    const { vectorOutside, mouseV } = getVectorOutsideElement(event)

    position.x = vectorOutside.x
    position.y = vectorOutside.y

    position.targetX = mouseV.x
    position.targetY = mouseV.y
  }

  function onMouseLeave(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setIsHovered(false)

    blob.disappear()

    const { vectorOutside } = getVectorOutsideElement(event)

    position.targetX = vectorOutside.x
    position.targetY = vectorOutside.y
  }

  function onMouseMove(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    position.targetX = getMousePositionX(event)
    position.targetY = getMousePositionY(event)
  }

  function getMousePositionX(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    const offsetLeft = elementBoundsRef.current.domRect?.left ?? 0
    return event.pageX - offsetLeft
  }

  function getMousePositionY(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    const offsetTop = elementBoundsRef.current.domRect?.top ?? 0
    return event.pageY - getScrollTop() - offsetTop
  }

  function getVectorOutsideElement(
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) {
    const rootElement = rootElementRef.current!

    const centerV = new Vector2(
      rootElement.clientWidth / 2,
      rootElement.clientHeight / 2
    )

    const mouseV = new Vector2(
      getMousePositionX(event),
      getMousePositionY(event)
    )

    const directionV = Vector2.fromDifference(centerV, mouseV)
    directionV.normalize()
    directionV.multiply(centerV.getLength() + blob.minRadius)

    centerV.add(directionV)

    return { vectorOutside: centerV, mouseV }
  }

  const ContainerElement = typeof to === 'string' ? InternalLink : 'span'

  return (
    <ContainerElement
      className={classNames(styles.container, className)}
      to={to}
      ref={rootElementRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      segmentdata={{
        anchor_text: employee?.firstName + ' ' + employee?.lastName,
        position: 'body',
        url: to,
      }}
    >
      {children(maskId, isMaskActive)}

      <svg className={styles.svg}>
        <defs>
          <clipPath id={maskId}>
            <path ref={pathRef} d={blob.toSvgPathD()} />
          </clipPath>
        </defs>
      </svg>
    </ContainerElement>
  )
}
