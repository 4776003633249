import { graphql } from 'gatsby'
import React, { useMemo, useRef, useState } from 'react'

import { BlogListingPageType } from '../../@import-types/contentful/BlogListingPage.types'
import { BlogPostContent } from '../../@import-types/contentful/BlogPost.types'
import Layout from '../../components/Layout'
import BlogPostTeaserCard from '../../components/_cards/BlogPostTeaserCard'
import FilterDropdown from '../../components/_elements/FilterDropdown'
import FilterHero from '../../components/_heroes/FilterHero'
import TeaserGridLayout from '../../components/_layouts/TeaserGridLayout'
import { RootPageQuery } from '../../graphql/global.graphql'
import {
  INTERNAL_TAG_CLIENT_PREFIX,
  useFilteredEntriesByTag,
} from '../../hooks/content/useFilteredEntiresByTag'
import useSortedEntries from '../../hooks/content/useSortedEntries'
import { useInputOptionsFromTags } from '../../hooks/content/useTagOptions'
import useLowercaseQueryObject from '../../hooks/routing/useLowercaseQueryObject'
import useFadeTransitionItems from '../../hooks/useFadeTransitionItems'

type PageBlogListingProps = RootPageQuery<{
  contentfulPageBlogListing: BlogListingPageType
  allContentfulBlogPost: {
    edges: Array<{
      node: BlogPostContent
    }>
  }
}>

export const clientQueryKey = 'client'

export default function PageBlogListing({
  data,
  pageContext,
  location,
}: PageBlogListingProps) {
  const { contentfulPageBlogListing, allContentfulBlogPost } = data

  const {
    title,
    heroSubtitle,
    heroFilterPreamble,
    showBlogPostsWithTags,
    sortingOrder,
  } = contentfulPageBlogListing

  const queries = useLowercaseQueryObject(location.search)

  const blogPosts = useMemo(() => {
    return allContentfulBlogPost.edges.map((edge) => edge.node)
  }, [allContentfulBlogPost])

  const allTags = useMemo(() => {
    const allTags = showBlogPostsWithTags ? [...showBlogPostsWithTags] : []

    if (clientQueryKey in queries) {
      const clientTagName = decodeURIComponent(queries[clientQueryKey])
      allTags.push({
        value: `${INTERNAL_TAG_CLIENT_PREFIX}${clientTagName}`,
        text: clientTagName,
      })
    }

    return allTags
  }, [showBlogPostsWithTags, queries])

  const options = useInputOptionsFromTags(allTags)

  const [selectedFilter, setSelectedFilter] = useState(() => {
    if (clientQueryKey in queries) {
      return options[options.length - 1].value
    }

    return options[0]?.value
  })

  const selectedBlogPosts = useFilteredEntriesByTag(blogPosts, selectedFilter)

  const sortedBlogPosts = useSortedEntries(selectedBlogPosts, sortingOrder)

  const gridContainerRef = useRef<HTMLDivElement>(null)

  const cacheValuesBeforeChange = useFadeTransitionItems({
    containerElRef: gridContainerRef,
    animateContainerHeight: false,
    durationMultiplier: 1.5,
    forceCloneSize: true,
    scale: 0.025,
  })

  function onFilterChange(value: string) {
    cacheValuesBeforeChange()
    setSelectedFilter(value)
  }

  return (
    <Layout
      data={data}
      basePageContent={contentfulPageBlogListing}
      pageContext={pageContext}
    >
      {title && (
        <FilterHero
          title={title}
          subtitle={heroSubtitle}
          filterPreamble={heroFilterPreamble}
          filter={
            <FilterDropdown
              value={selectedFilter}
              options={options}
              onChange={onFilterChange}
            />
          }
        />
      )}

      <TeaserGridLayout forwardRef={gridContainerRef}>
        {sortedBlogPosts.map((blogPost) => (
          <BlogPostTeaserCard key={blogPost.id} blogPost={blogPost} />
        ))}
      </TeaserGridLayout>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogListingPageById(
    $id: String!
    $startPageId: String!
    $locale: String!
    $tags: [String]!
  ) {
    contentfulPageBlogListing(id: { eq: $id }) {
      ...BasePageFragment

      title
      heroSubtitle
      heroFilterPreamble

      showBlogPostsWithTags {
        text
        value
      }

      sortingOrder {
        id
      }
    }

    allContentfulBlogPost(
      filter: {
        node_locale: { eq: $locale }
        tags: { elemMatch: { value: { in: $tags } } }
        title: { ne: null }
      }
      sort: { order: DESC, fields: createdAt }
    ) {
      edges {
        node {
          ...BlogPostTeaserFragment
        }
      }
    }

    ...GlobalFragment
  }
`
