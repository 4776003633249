import classNames from 'classnames'
import Img from 'gatsby-image'
import React from 'react'

import { ContentfulAsset } from '../../../@import-types/contentful/Asset.types'
import Colors from '../../../constants/Colors'
import { getComplementaryColor } from '../../../utils/colorUtils'
import BlobContainerZoomIn from '../../_layouts/BlobContainerZoomIn'
import SvgArrowRight from '../../_svg/SvgArrowRight'

import styles from './blob-teaser-card.module.scss'

interface BlobTeaserCardProps {
  to: string | null | undefined

  title: string | null | undefined
  description?: string | null

  blobImage: ContentfulAsset | null | undefined

  backgroundColor: string

  className?: string
}

export default function BlobTeaserCard({
  title,
  description,

  blobImage,

  backgroundColor,

  to,

  className,
}: BlobTeaserCardProps) {
  const complementaryColor = getComplementaryColor(backgroundColor)
  const maskTextColor =
    complementaryColor === Colors.White ? Colors.Brown : Colors.White

  const innerContent = (
    <span className={styles.content}>
      <span className={styles.contentTexts}>
        <span className={styles.title}>{title}</span>
        {description && (
          <span className={styles.description}>{description}</span>
        )}
      </span>

      <SvgArrowRight className={styles.arrow} color={complementaryColor} />
    </span>
  )

  return (
    <BlobContainerZoomIn
      className={classNames(styles.container, className)}
      to={to}
      blobOptions={{}}
    >
      {(maskId, isMaskActive) => (
        <>
          <span
            className={styles.colorContainer}
            style={{ backgroundColor, color: complementaryColor }}
          >
            {innerContent}
          </span>

          {isMaskActive && (
            <span
              className={styles.maskContainer}
              style={{ clipPath: `url(#${maskId})`, color: maskTextColor }}
            >
              {blobImage && (
                <Img
                  className={styles.image}
                  fluid={blobImage.fluid}
                  alt={blobImage.description || blobImage.title}
                />
              )}

              {innerContent}
            </span>
          )}
        </>
      )}
    </BlobContainerZoomIn>
  )
}
