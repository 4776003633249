import React from 'react'

import { BlogPostContent } from '../../../@import-types/contentful/BlogPost.types'
import Colors from '../../../constants/Colors'
import useBlogPostLink from '../../../hooks/routing/useBlogPostLink'
import BlobTeaserCard from '../BlobTeaserCard'

interface BlogPostTeaserCardProps {
  blogPost: BlogPostContent

  className?: string
}

export default function BlogPostTeaserCard({
  blogPost,

  className,
}: BlogPostTeaserCardProps) {
  const blogPostLink = useBlogPostLink(blogPost)

  return (
    <BlobTeaserCard
      className={className}
      to={blogPostLink}
      title={blogPost.teaserTitle}
      description={blogPost.teaserDescription}
      backgroundColor={blogPost.teaserColor ?? Colors.GreenLight}
      blobImage={blogPost.teaserImage}
    />
  )
}
