import { BlogPostContent } from '../../@import-types/contentful/BlogPost.types'
import useGlobalStartPage from '../globalContent/useGlobalStartPage'

import usePageLink from './usePageLink'

export default function useBlogPostLink(blogPost: BlogPostContent) {
  const globalStartPage = useGlobalStartPage()
  return usePageLink(
    globalStartPage?.blogListingPage?.slug ?? 'blog',
    blogPost.slug
  )
}
