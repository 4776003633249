import React from 'react'

import Colors from '../../../constants/Colors'

interface SvgArrowRightProps {
  color?: string | null
}

export default function SvgArrowRight({
  color,

  ...svgProps
}: SvgArrowRightProps & Omit<JSX.IntrinsicElements['svg'], 'color'>) {
  const renderColor = color ?? Colors.Brown

  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...svgProps}>
      <path
        className="svg-stroke"
        d="M3 15L27 15"
        stroke={renderColor}
        strokeWidth="2"
      />
      <path
        className="svg-stroke"
        d="M19 6L27 15L19 24"
        stroke={renderColor}
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  )
}
